var focusHelper = require("base/components/focus");
var slickCarousel = require("../components/slickCarousel");
var gtm = require("gtm/product/gtm");

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($("#quickViewModal").hasClass("show") && !$(".product-set").length) {
        pid = $($el).closest(".modal-content").find(".product-quickview").data("pid");
    } else if ($(".product-set-detail").length || $(".product-set").length) {
        pid = $($el).closest(".product-detail").find(".product-id").text();
    } else {
        pid = $(".product-detail:not('.bundle-item')").data("pid");
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $(".set-items").length) {
        quantitySelected = $($el).closest(".product-detail").find(".quantity-select");
    } else if ($el && $(".product-bundle").length) {
        var quantitySelectedModal = $($el).closest(".modal-footer").find(".quantity-select");
        var quantitySelectedPDP = $($el).closest(".bundle-footer").find(".quantity-select");
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $(".quantity-select");
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process attribute values
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processValues(attr, $productContainer, msgs) {
    var $variationsToggler = $productContainer.find("[data-attr='" + attr.id + "'] .variations-toggler");
    var selectedValueHtml;
    var selectedSwatchValue;

    $variationsToggler.find(".attr-selected-value").remove();
    $variationsToggler.find(".swatch-value-selected").remove();

    attr.values.forEach(function (attrValue) {
        var $attr = $productContainer.find("[data-attr='" + attr.id + "']");
        var $attrValue = $productContainer.find("[data-attr='" + attr.id + "'] [data-attr-value]").filter(function () {
            return $(this).data("attrValue") == attrValue.value;
        });
        var $button = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass("selected");
            selectedValueHtml = `<span class="attr-selected-value text-b2-global">${attrValue.displayValue}</span>`;
            $(selectedValueHtml).insertAfter($variationsToggler.find(".attr-name"));
            if (attr.swatchable) {
                selectedSwatchValue = `<span class="swatch-value-selected" style="background-image: url(${attrValue.images && attrValue.images.swatch.length > 0 ? attrValue.images.swatch[0].url : ""})"></span>`;
                $(selectedSwatchValue).insertAfter($variationsToggler.find(".attr-name"));
                $attrValue.siblings(".selected-assistive-text").text(msgs.assistiveSelectedText);
                $attr.find(".selected-swatch__name").text(attrValue.displayValue);

                if (attr.isContentAttribute) {
                    $attrValue.closest("button").addClass("selected");
                }

                if ($attr.find(".selected-swatch__family").length > 0) {
                    $attr.find(".selected-swatch__family").text(attrValue.fabricFamily);
                }

                if (attrValue.images && attrValue.images.swatch.length > 0) {
                    $attr.find(".selected-swatch__image").attr("src", attrValue.images.swatch[0].url.replace(/\s+/g, "%20"));
                }
                if ($attr.find(".selected-swatch__actions").length) {
                    $attr.find(".selected-swatch__actions .material-details-modal-button").attr("data-url", attrValue.showModalURL);
                    $attr.find(".selected-swatch__actions .add-to-cart--workroom").attr("data-pid", attrValue.orderableSwatchSKU);
                }
            }
        } else {
            if (attr.isContentAttribute) {
                $attrValue.closest("button").removeClass("selected");
            }
            $attrValue.removeClass("selected");
            if (attr.swatchable) {
                $attrValue.siblings(".selected-assistive-text").empty();
            }
        }

        if (attrValue.url) {
            $button.attr("data-url", attrValue.url);
        } else {
            $button.removeAttr("data-url");
        }

        // Disable if not selectable
        $attrValue.removeClass("selectable unselectable");
        $button.attr("disabled", !attrValue.selectable);

        $attrValue.addClass(attrValue.selectable ? "selectable" : "unselectable");
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    attrs.forEach(function (attr) {
        processValues(attr, $productContainer, msgs);
        if (attr.hasTabs) {
            $(".color-attribute-number").text(attr.valueCount[attr.selectedValue.pairedValue]);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = "";
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = "<li><div>" + response.resources.info_selectforstock + "</div></li>";
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += "<li><div>" + message + "</div></li>";
        });
    }

    $($productContainer).trigger("product:updateAvailability", {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}


/**
 * Updates the wishlist status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateWishlist(response, $productContainer) {
    var iconEmpty = $productContainer.closest(".product-detail .product-main-region").find(".wishlist-add");
    var iconFilled = $productContainer.closest(".product-detail .product-main-region").find(".wishlist-remove");
    if (response.isInWhishlist) {
        iconEmpty.addClass("d-none");
        iconFilled.removeClass("d-none");
    } else {
        iconFilled.addClass("d-none");
        iconEmpty.removeClass("d-none");
    }
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return "";
    }

    var html = "";

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === "dimensions") {
            attributeGroup.attributes.forEach(function (attribute) {
                html += "<div class='attribute-values'><span class='text-b2-medium'>" + attribute.label + "</span> " +
                    "<span class='text-b2-global'>" + attribute.value + "</span></div>";
            });
        }
    });

    var viewAllLabel = $(".main-attributes").data("view-all");
    html += `<a href="#" class="text-b2-underlined specifications-toggler">${viewAllLabel}</a>`;

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find(".product-options").empty().html(optionsHtml);
}

/**
 * Dynamically creates Slick carousel slides from response containing images
 * @param {Object[]} images - Array of product images, along with related information
 *
 * @param {jQuery} type - type of product images (large, small..)
 */
function getSliderElements(images, type) {
    var htmlTemplateString = "";
    var htmlSubString = "";

    images[type].forEach(function (image, idx) {
        if (type == "large") {
            var highResImg = images.large[idx] || image;
            htmlSubString = `<div class="slide-inside">
                <div class="anchor-image text-center position-relative">
                <img class="product-slider-image img-fluid" src="${image.url}" srcset="${image.srcset || ""}" sizes="${image.sizes || ""}" data-high-res-src="${highResImg.url}" data-modal-srcset="${image.modal && image.modal.srcset ? image.modal.srcset : ""}" data-modal-sizes="${image.modal && image.modal.sizes ?  image.modal.sizes : ""}" alt="${image.alt} image number ${image.index}" loading="${idx > 0 ? "lazy" : "auto"}" itemprop="image"/>
                </div></div>`;
        } else {
            htmlSubString = `<div class="slide-inside">
            <div class="thumb-image overflow-hidden">
            <img src="${image.url}" srcset="${image.srcset || ""}" sizes="${image.sizes || ""}" data-modal-sizes="${image.modal.sizes || ""}" alt="${image.alt}" loading="${idx > 4 ? "lazy" : "auto"}" itemprop="image"/>
            </div></div>`;
        }

        htmlTemplateString += htmlSubString;
    });

    return htmlTemplateString;
}

/**
 * Dynamically creates Slick carousel from response containing images
 * @param {Object[]} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Boolean} isDrive - determines if product is a drive product
 * @param {Boolean} isVirtualSku - determines if carousel should switch to 360 view
 * @param {Boolean} preventGalleryRefresh - Drive indicator to skip images rerender
 */
function updateProductImages(response, $productContainer, isDrive, isVirtualSku, preventGalleryRefresh) {
    var primaryImageUrls;

    if (preventGalleryRefresh === true || preventGalleryRefresh === "true") {
        return;
    }

    if (response.isDriveCustomizerUpdate && !(response.bundleProduct && response.bundleProduct.images)) {
        primaryImageUrls = {
            large: [],
            small: []
        };
        response.products.forEach(function (product) {
            primaryImageUrls.large = primaryImageUrls.large.concat(product.images.large);
            primaryImageUrls.small = primaryImageUrls.small.concat(product.images.small);
        });
    } else {
        primaryImageUrls = response.bundleProduct && response.bundleProduct.images ? response.bundleProduct.images : response.product.images;
    }

    var $mainSlider = $productContainer.find(".primary-images-carousel .slick-slider").first();
    var $thumbSlider = $productContainer.find("[id^=pdp-thumb-slider]").first();
    var sliderIndex = $mainSlider.data("sliderIndex");
    var mainSlliderElements;
    var thumbSlliderElements;

    if (primaryImageUrls.large && primaryImageUrls.large.length) {
        mainSlliderElements = getSliderElements(primaryImageUrls, "large");
        $mainSlider.slick("unslick");
        $mainSlider.find(".slide-inside:not(.slide-inside--customizer)").remove();
        $mainSlider.prepend(mainSlliderElements);
        $(".pdp-images-print-container").find("img").attr("src", primaryImageUrls.large[0].url);
    }
    if (primaryImageUrls.small && primaryImageUrls.small.length && $thumbSlider.hasClass("slick-initialized")) {
        thumbSlliderElements = getSliderElements(primaryImageUrls, "small");
        $thumbSlider.slick("unslick");
        $thumbSlider.find(".slide-inside:not(.slide-inside--preview)").remove();
        $thumbSlider.prepend(thumbSlliderElements);
    }
    if (mainSlliderElements || thumbSlliderElements) {
        slickCarousel.initPDPSliderByIndex(sliderIndex, isDrive, isVirtualSku);
    }
}

function calculateSavings() {
    var $priceSelector = $(".single-price .price");
    var $productContainer = $priceSelector.closest(".product-detail");
    var $savingsContainer = $productContainer.find(".attributes .savings");
    var salesPriceSelector = $priceSelector.find(".sales .value");
    var originalPriceSelector = $priceSelector.find(".strike-through .value");
    var savingsLabel = $savingsContainer.data("label");
    var salesPrice;
    var originalPrice;
    var discount;
    var percentageDiscount;
    var savingsMessage;

    if (!$productContainer.parents(".choose-bonus-product-dialog").length > 0) {
        if (originalPriceSelector.length && salesPriceSelector.length) {
            salesPrice = Math.round($(salesPriceSelector[0]).text().replace(/[^0-9.-]+/g, ""));
            originalPrice = Math.round($(originalPriceSelector[0]).text().replace(/[^0-9.-]+/g, ""));
            discount = Math.round(originalPrice - salesPrice);
            percentageDiscount = Math.round((discount * 100) / originalPrice) + "%";
            savingsMessage = `${savingsLabel} ${percentageDiscount} (${"$" + discount.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")})`;
            $savingsContainer.each(function () {
                $(this).empty().text(savingsMessage);

                if (discount <= 0) {
                    $(this).addClass("d-none");
                } else {
                    $(this).removeClass("d-none");
                }
            });
        } else {
            $savingsContainer.each(function () {
                $(this).empty();
            });
        }
    }
}

/**
 * updates shipping message based on product shipping flag
 * @param {Object} response - response from Ajax call                 
 */
function updateShippingMessage(response) {
    var $shippingMessage = $(".shipping-message");

    if (response && $shippingMessage && $shippingMessage.length) {
        var freeShippingFlag = $shippingMessage.data("free-shipping-flag");
        var showShippingMessage = false;

        if (response.product && response.product.ShippingFlag && response.product.ShippingFlag.trim().toLowerCase() === freeShippingFlag) {
            showShippingMessage = true;
        } else if (response.products && response.products.length) {
            var allProductsFreeShipping = true;

            for (let i = 0; i < response.products.length; i++) {
                var product = response.products[i];
                if (!(product && product.ShippingFlag && product.ShippingFlag.trim().toLowerCase() === freeShippingFlag)) {
                    allProductsFreeShipping = false;
                }
            }

            if (allProductsFreeShipping) {
                showShippingMessage = true;
            }
        }

        if (showShippingMessage) {
            $shippingMessage.removeClass("d-none");
        } else {
            $shippingMessage.addClass("d-none");
        }
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents(".choose-bonus-product-dialog").length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === "variant";
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent(".bonus-product-item")
                .data("pid", response.product.id);

            $productContainer.parent(".bonus-product-item")
                .data("ready-to-order", response.product.readyToOrder);
        }
    }

    // Update product images
    updateProductImages(response, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $(".prices .price", $productContainer).length
            ? $(".prices .price", $productContainer)
            : $(".prices .price");
        $priceSelector.each(function () {
            $(this).find(".sales").find(".synchrony-price-widget").text(response.product.price.sales.decimalPrice);
            $(this).find(".sales").find(".value").text(response.product.price.sales.pdpTileFormat).attr("content", response.product.price.sales.decimalPrice);
            $(this).find(".list").find(".synchrony-price-widget").text(response.product.price.list.decimalPrice);
            $(this).find(".list").find(".value").text(response.product.price.list.pdpTileFormat).attr("content", response.product.price.list.decimalPrice);

            if (response.product.price && response.product.price.sales && response.product.price.list
                && response.product.price.sales.decimalPrice && response.product.price.list.decimalPrice)
            {
                if (response.product.price.sales.decimalPrice < response.product.price.list.decimalPrice) {
                    $(this).find(".list").removeClass("d-none");
                } else {
                    $(this).find(".list").addClass("d-none");
                }
            }
        });
        calculateSavings();
    }

    // Update promotions
    $productContainer.find(".promotions").empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);
    updateWishlist(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find(".select-bonus-product");
        $selectButton.trigger("bonusproduct:updateSelectButton", {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $("button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global").trigger("product:updateAddToCart", {
            product: response.product, $productContainer: $productContainer
        }).trigger("product:statusUpdate", {
            product: response.product, $productContainer: $productContainer
        });
    }

    // Update attributes
    $productContainer.find(".main-attributes").empty()
        .html(getAttributesHtml(response.product.attributes));

    updateShippingMessage(response);
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent(".bonus-product-item").length <= 0) {
        var maxQty = quantities.length < 100 ? quantities.length : "99";
        var selectedQty;
        var url;

        quantities.forEach(function (quantity) {
            if (quantity.selected) {
                selectedQty = quantity.value;
                url = quantity.url;
            }
        });

        var $qtySelector = getQuantitySelector($productContainer);

        $qtySelector.data("max-qty", maxQty);
        $qtySelector.data("url", url);
        $qtySelector.val(selectedQty);

        var priceSelector = $(".cart-and-ipay .prices .price .sales .value", $productContainer).length
            ? $(".cart-and-ipay .prices .price .sales .value", $productContainer)
            : $(".cart-and-ipay .prices .price .sales .value");
        var price = Math.round($(priceSelector[0]).text().replace(/[^0-9.-]+/g, ""));
        var finalPrice = price * selectedQty;

        priceSelector.text("$" + finalPrice.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $("body").trigger("product:beforeAttributeSelect",
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: "GET",
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $("body").trigger("product:afterAttributeSelect",
                    { data: data, container: $productContainer });
                gtm.updateGTM(data, $productContainer);
                $("body").trigger("pixel:updateAddToCartSku", data);
                $("body").trigger("pinterest:updateAddToCartData", data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $(".add-to-cart-url").val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".choice-of-bonus-product");
    var footer = $html.find(".modal-footer").children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $(".modal-body").spinner().start();

    if ($("#chooseBonusProductModal").length !== 0) {
        $("#chooseBonusProductModal").remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = "<!-- Modal -->"
        + "<div class='modal fade' id='chooseBonusProductModal' tabindex='-1' role='dialog'>"
        + "<span class='enter-message sr-only' ></span>"
        + "<div class='modal-dialog choose-bonus-product-dialog' "
        + "data-total-qty='" + data.maxBonusItems + "'"
        + "data-UUID='" + data.uuid + "'"
        + "data-pliUUID='" + data.pliUUID + "'"
        + "data-addToCartUrl='" + data.addToCartUrl + "'"
        + "data-pageStart='0'"
        + "data-pageSize='" + data.pageSize + "'"
        + "data-moreURL='" + data.showProductsUrlRuleBased + "'"
        + "data-bonusChoiceRuleBased='" + data.bonusChoiceRuleBased + "'>"
        + "<!-- Modal content-->"
        + "<div class='modal-content'>"
        + "<div class='modal-header'>"
        + "    <span class=''>" + data.labels.selectprods + "</span>"
        + "    <button type='button' class='close pull-right' data-dismiss='modal'>"
        + "        <span aria-hidden='true'>&times;</span>"
        + "        <span class='sr-only'> </span>"
        + "    </button>"
        + "</div>"
        + "<div class='modal-body'></div>"
        + "<div class='modal-footer'></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
    $(".modal-body").spinner().start();

    $.ajax({
        url: bonusUrl,
        method: "GET",
        dataType: "json",
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $("#chooseBonusProductModal .modal-body").empty();
            $("#chooseBonusProductModal .enter-message").text(response.enterDialogMessage);
            $("#chooseBonusProductModal .modal-header .close .sr-only").text(response.closeButtonText);
            $("#chooseBonusProductModal .modal-body").html(parsedHtml.body);
            $("#chooseBonusProductModal .modal-footer").html(parsedHtml.footer);
            $("#chooseBonusProductModal").modal("show");
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $(".minicart").trigger("count:update", response);
    var messageType = response.error ? "alert-danger" : "alert-success";
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($(".add-to-cart-messages").length === 0) {
            $("body").append(
                "<div class='add-to-cart-messages'></div>"
            );
        }

        $(".add-to-cart-messages").append(
            "<div class='alert " + messageType + " add-to-basket-alert text-center' role='alert'>"
            + response.message
            + "</div>"
        );

        setTimeout(function () {
            $(".add-to-basket-alert").remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $(".bundle-item").each(function () {
        childProducts.push({
            pid: $(this).find(".product-id").text(),
            quantity: parseInt($(this).find("label.quantity").data("quantity"), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find(".product-option")
        .map(function () {
            var $elOption = $(this).find(".options-select");
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find("option[value='" + urlValue + "']")
                .data("value-id");
            return {
                optionId: $(this).data("option-id"),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: "GET",
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Edit" button
 * @param {string} response - ajax response from clicking the edit cart item button
 */
function handleEditCartItem(response) {
    $(".minicart").trigger("count:update", response);
    var messageType = response.error ? "alert-danger" : "alert-success";
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($(".add-to-cart-messages").length === 0) {
            $("body").append(
                "<div class='add-to-cart-messages'></div>"
            );
        }

        $(".add-to-cart-messages").append(
            "<div class='alert " + messageType + " add-to-basket-alert text-center' role='alert'>"
            + response.message
            + "</div>"
        );

        setTimeout(function () {
            $(".add-to-basket-alert").remove();
        }, 5000);
    }
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        },
        calculateSavings: calculateSavings,
        updateProductImages: updateProductImages,
        handlePostCartAdd: handlePostCartAdd,
        updateShippingMessage: updateShippingMessage
    },

    focusChooseBonusProductModal: function () {
        $("body").on("shown.bs.modal", "#chooseBonusProductModal", function () {
            $("#chooseBonusProductModal").siblings().attr("aria-hidden", "true");
            $("#chooseBonusProductModal .close").focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $("body").on("hidden.bs.modal", "#chooseBonusProductModal", function () {
            $("#chooseBonusProductModal").siblings().attr("aria-hidden", "false");
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $("body").on("keydown", "#chooseBonusProductModal", function (e) {
            var focusParams = {
                event: e,
                containerSelector: "#chooseBonusProductModal",
                firstElementSelector: ".close",
                lastElementSelector: ".add-bonus-products"
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on("click", ".attribute .card-body button:not(.material-details-modal-button):not(.add-to-cart--workroom)", function (e) {
            e.preventDefault();

            if ($(this).attr("disabled")) {
                return;
            }
            var $productContainer = $(this).closest(".set-item");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".product-detail");
            }

            attributeSelect($(this).attr("data-url"), $productContainer);
        });
    },

    tabAttribute: function () {
        $(document).on("show.bs.tab", ".nav-attribute", function () {
            var $productContainer = $(this).closest(".set-item");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".product-detail");
            }

            var resetAttributeName = $(this).attr("data-reset-attribute-name");
            var concatString = "&resetAttributeName=" + resetAttributeName;

            var url = $(this).attr("data-url").concat(concatString);

            attributeSelect(url, $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on("change", ".options-select", function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest(".set-item");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".product-detail");
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },

    availability: function () {
        $(document).on("click", ".product-detail .btn-change-quantity", function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest(".product-detail");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".modal-content").find(".product-quickview");
            }

            var $qtySelector = getQuantitySelector($productContainer);
            var currentValue = $qtySelector.val();
            var maxValue = $qtySelector.data("max-qty");
            var oldUrl = $qtySelector.data("url");
            var withoutQty = oldUrl.split("&quantity=")[0];
            var newValue = +currentValue;
            if ($(this).hasClass("btn-increase-quantity") && maxValue < 100 && maxValue > currentValue) {
                newValue += 1;
            }
            if ($(this).hasClass("btn-decrease-quantity") && currentValue > 1) {
                newValue -= 1;
            }
            if ($(this).hasClass("btn-decrease-quantity") && currentValue == 1) {
                return;
            }
            if (newValue == 1) {
                $(this).closest(".product-detail").find(".btn-decrease-quantity").addClass("disabled");
            } else {
                $(this).closest(".product-detail").find(".btn-decrease-quantity").removeClass("disabled");
            }
            $qtySelector.val(newValue);
            var url = withoutQty + "&quantity=" + newValue;
            $qtySelector.data("url", url);
            attributeSelect($qtySelector.data("url"),
                $productContainer);
        });
    },

    addToCart: function () {
        $(document).on("click", "button.add-to-cart, button.add-to-cart-global", function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            $("body").trigger("product:beforeAddToCart", this);

            var addToCartContainer = $(this).closest(".cart-and-ipay").find(".gtm-add-to-cart-container");
            var gtmAddToCartObj = addToCartContainer.find(".gtm-add-to-cart").attr("data-gtm-add-to-cart");
            $("body").trigger("gtm:standartEvent", {
                event: "add_to_cart",
                data: JSON.parse(gtmAddToCartObj)
            });

            var fbqPixelPID = $(".pixel-add-to-cart").data("pixel-add-to-cart-pid");
            if (!fbqPixelPID) {
                fbqPixelPID = $(this).data("pid");
            }
            var addToCartObj = {
                content_type: "product_group",
                content_ids: [fbqPixelPID]
            };
            $("body").trigger("pixel:trackEvent", {
                event: "AddToCart",
                data: addToCartObj
            });

            var pintrestAddToCartObj = $(".pinterest-add-to-cart").data("pinterest-add-to-cart");
            $("body").trigger("pinterest:trackEvent", {
                event: "addtocart",
                data: pintrestAddToCartObj
            });

            if ($(".set-items").length && $(this).hasClass("add-to-cart-global")) {
                setPids = [];

                $(".product-detail").each(function () {
                    if (!$(this).hasClass("product-set-detail")) {
                        setPids.push({
                            pid: $(this).find(".product-id").text(),
                            qty: $(this).find(".quantity-select").val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest(".product-detail");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".quick-view-dialog").find(".product-detail");
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$(".bundle-item").length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger("updateAddToCartFormData", form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $("body").trigger("product:afterAddToCart", data);
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    selectBonusProduct: function () {
        $(document).on("click", ".select-bonus-product", function () {
            var $choiceOfBonusProduct = $(this).parents(".choice-of-bonus-product");
            var pid = $(this).data("pid");
            var maxPids = $(".choose-bonus-product-dialog").data("total-qty");
            var submittedQty = parseInt($choiceOfBonusProduct.find(".bonus-quantity-select").val(), 10);
            var totalQty = 0;
            $.each($("#chooseBonusProductModal .selected-bonus-products .selected-pid"), function () {
                totalQty += $(this).data("qty");
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find(".product-option").data("option-id");
            var valueId = $choiceOfBonusProduct.find(".options-select option:selected").data("valueId");
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ""
                    + "<div class='selected-pid row' "
                    + "data-pid='" + pid + "'"
                    + "data-qty='" + submittedQty + "'"
                    + "data-optionID='" + (optionID || "") + "'"
                    + "data-option-selected-value='" + (valueId || "") + "'"
                    + ">"
                    + "<div class='col-sm-11 col-9 bonus-product-name' >"
                    + $choiceOfBonusProduct.find(".product-name").html()
                    + "</div>"
                    + "<div class='col-1'><i class='fa fa-times' aria-hidden='true'></i></div>"
                    + "</div>"
                    ;
                $("#chooseBonusProductModal .selected-bonus-products").append(selectedBonusProductHtml);
                $(".pre-cart-products").html(totalQty);
                $(".selected-bonus-products .bonus-summary").removeClass("alert-danger");
            } else {
                $(".selected-bonus-products .bonus-summary").addClass("alert-danger");
            }
        });
    },

    removeBonusProduct: function () {
        $(document).on("click", ".selected-pid", function () {
            $(this).remove();
            var $selected = $("#chooseBonusProductModal .selected-bonus-products .selected-pid");
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data("qty"), 10);
                });
            }

            $(".pre-cart-products").html(count);
            $(".selected-bonus-products .bonus-summary").removeClass("alert-danger");
        });
    },

    enableBonusProductSelection: function () {
        $("body").on("bonusproduct:updateSelectButton", function (e, response) {
            $("button.select-bonus-product", response.$productContainer).attr("disabled",
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $("button.select-bonus-product", response.$productContainer).data("pid", pid);
        });
    },

    showMoreBonusProducts: function () {
        $(document).on("click", ".show-more-bonus-products", function () {
            var url = $(this).data("url");
            $(".modal-content").spinner().start();
            $.ajax({
                url: url,
                method: "GET",
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $(".modal-body").append(parsedHtml.body);
                    $(".show-more-bonus-products:first").remove();
                    $(".modal-content").spinner().stop();
                },
                error: function () {
                    $(".modal-content").spinner().stop();
                }
            });
        });
    },

    addBonusProductsToCart: function () {
        $(document).on("click", ".add-bonus-products", function () {
            var $readyToOrderBonusProducts = $(".choose-bonus-product-dialog .selected-pid");
            var queryString = "?pids=";
            var url = $(".choose-bonus-product-dialog").data("addtocarturl");
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data("qty"), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data("optionid") && $(this).data("option-selected-value")) {
                        option = {};
                        option.optionId = $(this).data("optionid");
                        option.productId = $(this).data("pid");
                        option.selectedValueId = $(this).data("option-selected-value");
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data("pid"),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($(".pre-cart-products").html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + "&uuid=" + $(".choose-bonus-product-dialog").data("uuid");
            queryString = queryString + "&pliuuid=" + $(".choose-bonus-product-dialog").data("pliuuid");
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: "POST",
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $("#chooseBonusProductModal").modal("hide");
                        if ($(".add-to-cart-messages").length === 0) {
                            $("body").append("<div class='add-to-cart-messages'></div>");
                        }
                        $(".add-to-cart-messages").append(
                            "<div class='alert alert-danger add-to-basket-alert text-center'"
                            + " role='alert'>"
                            + data.errorMessage + "</div>"
                        );
                        setTimeout(function () {
                            $(".add-to-basket-alert").remove();
                        }, 3000);
                    } else {
                        $(".configure-bonus-product-attributes").html(data);
                        $(".bonus-products-step2").removeClass("hidden-xl-down");
                        $("#chooseBonusProductModal").modal("hide");

                        if ($(".add-to-cart-messages").length === 0) {
                            $("body").append("<div class='add-to-cart-messages'></div>");
                        }
                        $(".minicart-quantity").html(data.totalQty);
                        $(".add-to-cart-messages").append(
                            "<div class='alert alert-success add-to-basket-alert text-center'"
                            + " role='alert'>"
                            + data.msgSuccess + "</div>"
                        );
                        setTimeout(function () {
                            $(".add-to-basket-alert").remove();
                            if ($(".cart-page").length) {
                                location.reload(); // eslint-disable-line
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    editCartItem: function () {
        $(document).on("click", "button.edit-cart-item", function () {
            var editCartItemURL;
            var pid;
            var pidsObj;
            var setPids;
            var uuid;

            $("body").trigger("product:beforeEditCartItem", this);

            if ($(".set-items").length && $(this).hasClass("add-to-cart-global")) {
                setPids = [];

                $(".product-detail").each(function () {
                    if (!$(this).hasClass("product-set-detail")) {
                        setPids.push({
                            pid: $(this).find(".product-id").text(),
                            qty: $(this).find(".quantity-select").val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));
            uuid = $(".edit-cart-item").data("uuid");

            var $productContainer = $(this).closest(".product-detail");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".quick-view-dialog").find(".product-detail");
            }

            editCartItemURL = $(".edit-cart-item-url").val();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this)),
                uuid: uuid
            };

            if (!$(".bundle-item").length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger("updateEditCartItemFormData", form);
            if (editCartItemURL) {
                $.ajax({
                    url: editCartItemURL,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        handleEditCartItem(data);
                        $("body").trigger("product:afterEditCartItem", data);
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }

        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl
};
